
import Vue from "vue";

import Container from "@/components/Container.vue";
import Hero from "@/components/Hero.vue";

export default Vue.extend({
  components: {
    Container,
    Hero,
  },
});
